import React from 'react'
import {
  PlanCard,
  PlanCardsList,
} from '@3beehivetech/components'
import useNavigate from '../../hooks/useNavigate'

export default function BeeboxPlans() {
  const navigate = useNavigate('https://stores.3bee.com/signup')

  const orderNow = () => navigate?.()

  return (
    <PlanCardsList>
      <PlanCardsList.Item>
        <PlanCard
          buttonLabel="ORDINA ORA"
          onButtonClick={orderNow}
          labels={[
            { value: '500', description: 'Api protette' },
            { description: 'Fiori impollinati', value: '10 mila' },
          ]}
          name="Box Segui le Api"
          price="€11.99"
        >
          <PlanCard.Benefit text="L’adozione di un alveare tecnologico 3Bee per un anno" />
          <PlanCard.Benefit text="Certificato di adozione personalizzato" />
          <PlanCard.Benefit text="Accesso all'app 3Bee per monitorare le api grazie a foto e video delle api" />
          <PlanCard.Benefit text="Un libro educativo di 170 pagine con curiosità sulle api e la prima guida di mieli d'Italia" />
        </PlanCard>
      </PlanCardsList.Item>
      <PlanCardsList.Item>
        <PlanCard
          buttonLabel="ORDINA ORA"
          onButtonClick={orderNow}
          labels={[
            { value: '1000', description: 'Api protette' },
            { description: 'Fiori impollinati', value: '500 mila' },
            { description: 'Miele ricevuto', value: '0,5 kg' },
          ]}
          name="Box Birichina"
          variant="green"
          price="€24.99"
        >
          <PlanCard.Benefit text="0,5kg di miele 100% italiano e genuino" />
          <PlanCard.Benefit text="L’adozione di un alveare tecnologico 3Bee per un anno" />
          <PlanCard.Benefit text="Certificato di adozione personalizzato" />
          <PlanCard.Benefit text="Accesso all'app 3Bee per monitorare le api grazie a foto e video delle api" />
          <PlanCard.Benefit text="Un libro educativo di 170 pagine con curiosità sulle api e la prima guida di mieli d'Italia" />
        </PlanCard>
      </PlanCardsList.Item>
      <PlanCardsList.Item>
        <PlanCard
          variant="pink"
          buttonLabel="ORDINA ORA"
          onButtonClick={orderNow}
          labels={[
            { value: '2000', description: 'Api protette' },
            { description: 'Fiori impollinati', value: '1 milione' },
            { description: 'Miele ricevuto', value: '1 kg' },
          ]}
          name="Box Esploratore"
          price="€34.99"
        >
          <PlanCard.Benefit text="1 kg di miele 100% italiano e genuino" />
          <PlanCard.Benefit text="L’adozione di un alveare tecnologico 3Bee per un anno" />
          <PlanCard.Benefit text="Certificato di adozione personalizzato" />
          <PlanCard.Benefit text="Accesso all'app 3Bee per monitorare le api grazie a foto e video delle api" />
          <PlanCard.Benefit text="Un libro educativo di 170 pagine con curiosità sulle api e la prima guida di mieli d'Italia" />
        </PlanCard>
      </PlanCardsList.Item>
    </PlanCardsList>
  )
}
